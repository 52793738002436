@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

body {
  margin: 0;
  scroll-behavior: smooth;
}

@layer components {
  .container {
    @apply px-[20px];
  }
}

:root {
  --linear2: linear-gradient(
      0deg,
      rgba(242, 195, 71, 0.1),
      rgba(242, 195, 71, 0.1)
    ),
    linear-gradient(0deg, #ffffff, #ffffff);
  --linear: linear-gradient(to right, #0de7b9, #5355ed);
  --linear1: linear-gradient(to right, #f69621, #f69621);
  --primary: #f69621;
  --secondary: #f4f6f8;
  --neutral1: #d3d4d6;
}

body {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth !important;
}

.linear-text {
  display: inline;
  background: linear-gradient(to right, #0de7b9, #5355ed);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.text-gradient-purple-pink[_ngcontent-serverApp-c87] {
  background: linear-gradient(to right, #9b4dca, #ff2d55);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.catalysts-linear {
  background: linear-gradient(
    87.58deg,
    #9a3e8f -9.27%,
    #c53660 26.48%,
    #e93141 72.2%,
    #f98b51 98.87%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ant-radio-button-checked {
  background-color: var(--primary);
}

.ant-radio-button-checked:hover {
  color: var(--primary);
}

.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.loader_container {
  position: fixed;
  z-index: 9999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
}

.ant-spin .ant-spin-dot-item {
  background-color: var(--primary);
}

.ant-table-cell {
  color: #2e3147;
  font-weight: 400;
}

.btn-pressed {
  background: #eef0f4;
  border-radius: 15%;
  box-shadow: inset 9.91px 9.91px 20px #d9dade,
    inset -9.91px -9.91px 20px #ffffff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-select-selector {
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: 0px !important;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  width: 160px !important;
  height: 160px !important;
}

.w-\[fit-content\] {
  width: fit-content !important ;
}

.ant-upload-list-item-container {
  width: 160px !important;
  height: 160px !important;
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  background-color: transparent !important;
  border-inline-end: 0px;
}

.ant-switch {
  background-color: #e5e5e5 !important;
}

.ant-switch-checked {
  background-color: #4784ca !important;
}
